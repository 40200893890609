<template>
  <v-container fluid>
    <crm-table-pageable-new
        :headers="headers"
        :data="clients"
        :config="config"
        :loaded-options="options"
        :total-elements="totalElements"
        :total-pages="totalPages"
        @onChangeData="onChangeData"
        @handleActions="handle"
    ></crm-table-pageable-new>
    <v-dialog v-model="dialogInfo" width="1200px">
      <v-card>
        <v-card-title>
          Профиль покупателя
        </v-card-title>
        <v-divider class="elevation-3"></v-divider>
        <v-card-text>
          <v-row class="my-5">
            <v-col cols="3">
              <v-text-field v-model="item.firstName" hide-details label="Имя" readonly/>
              <v-text-field v-model="item.lastName" hide-details label="Фамилия" readonly/>
              <v-text-field v-model="item.phoneNumber" hide-details label="Телефон" readonly/>
              <v-text-field v-model="item.email" hide-details label="Email" readonly/>
            </v-col>
            <v-col>
              <crm-table :headers="headersOrders" :data="dataOrders" @handleActions="handle"/>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import crmTable from '@/components/crm-table/index.vue';
import crmTablePageableNew from '@/components/crm-table-pageable-new/index.vue';

export default {
  components: {
    'crm-table': crmTable,
    'crm-table-pageable-new': crmTablePageableNew,
  },
  data() {
    return {
      headers: [
        {
          value: 'firstName', sortable: false, text: 'Имя', type: 'defaultItem',
        },
        {
          value: 'lastName', sortable: false, text: 'Фамилия', type: 'defaultItem',
        },
        {
          value: 'phoneNumber', sortable: false, text: 'Телефон', type: 'defaultItem',
        },
        {
          value: 'email', sortable: false, text: 'Email', type: 'defaultItem',
        },
      ],
      headersOrders: [
        {
          value: 'id', sortable: false, text: '#', type: 'defaultItem',
        },
        {
          value: 'createdDate', sortable: false, text: 'Дата заказа', type: 'defaultItem',
        },
        {
          value: 'operation', sortable: false, text: 'Операция', type: 'defaultItem',
        },
        {
          value: 'paymentType', sortable: false, text: 'Способ оплаты', type: 'defaultItem',
        },
        {
          value: 'status', sortable: false, text: 'Статус', type: 'defaultItem',
        },
      ],
      dataOrders: [],
      visible: false,
      item: {},
      dialogInfo: false,
      config: {
        add: false,
        search: true,
        pageable: true,
      },
      clients: [],
      totalElements: 0,
      totalPages: 0,
      url: '/api/v1/crm/users',
      options: {
        page: 1,
        size: 10,
      },
    };
  },
  created() {
    this.onChangeData(this.$route.query);
  },
  methods: {
    onChangeData(options) {
      let resultOptions = { ...options };
      if (!Object.values(options).length) {
        resultOptions = { ...this.options };
      }
      this.$router.replace({
        path: this.$route.path,
        params: {},
        query: resultOptions,
      }).catch(() => {});
      this.options = { ...resultOptions };
      this.getClients();
    },
    getClients() {
      const params = this.$route.query;
      const formattedUrl = this.url;

      this.$loading(true);
      this.$api.get(formattedUrl, {
        params: {
          page: params.page - 1,
          size: params.size,
          sort: params.sort,
          search: params.search,
        },
      }).then((response) => {
        this.totalElements = response.totalElements;
        this.totalPages = response.totalPages;
        this.clients = response.content;
      }).finally(() => {
        this.$loading(false);
      });
    },
    getClientOrders() {
      this.$api.get(`/api/v1/crm/users/${this.item.uuid}/orders`).then((response) => {
        this.$store.dispatch('entity/actionSetEntity', {
          state: 'orders',
          data: Object.freeze(response.content),
        });
        this.dataOrders = [...response.content];
      });
    },
    async handle(event) {
      if (event.type === 'info') {
        Object.assign(this.item, event.item);
        await this.getClientOrders();
        this.dialogInfo = true;
      }
    },
  },
};
</script>

<style scoped>

</style>
